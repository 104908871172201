<!--
 * @Author: Jerry
 * @Date: 2021-01-12 17:20:30
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-04 10:36:47
 * @Description: 搜索区域
-->
<template>
  <div class="groups">
    <div class="Mbm10" v-if="activeCondition.length > 0">
      <span class="F14 co_333">搜索条件：</span>
      <el-tag
        class="Mrt5"
        closable
        type="primary"
        size="small"
        v-for="(item, index) in activeCondition"
        :key="item.propertyId"
        @close="closeCondition(item.propertyId, index)"
        >{{ item.propertyName }}：{{ item.value }}</el-tag
      >
    </div>
    <div class="group">
      <div
        class="frow"
        v-for="(list, index) in paramsList"
        :key="list.propertyId"
      >
        <div class="row" v-if="list.show != false">
          <div class="group_head bg_f4f4">{{ list.propertyName }}：</div>
          <div class="group_body">
            <div class="items">
              <el-link
                class="item"
                :underline="false"
                v-for="item in list.propertyValueList"
                :key="item"
                @click="addCondition(item, index)"
                >{{ item }}</el-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 商品列表的搜索区域
export default {
  name: "searchArea",
  props: {
    paramsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 选中的条件
      activeCondition: [],
      // 选中的条件组
      conditionList: [],
    };
  },
  methods: {
    // 新增条件
    addCondition(item, index) {
      this.activeCondition.push({
        propertyId: this.paramsList[index].propertyId,
        propertyName: this.paramsList[index].propertyName,
        value: item,
      });
      this.paramsList[index].show = false;
      this.conditionChange();
    },
    // 删除条件
    closeCondition(id, idx) {
      this.activeCondition.splice(idx, 1);
      let index = 0;
      for (let x = 0; x < this.paramsList.length; x++) {
        if (this.paramsList[x].propertyId == id) {
          index = x;
        }
      }
      this.paramsList[index].show = true;
      this.conditionChange();
    },
    // 修改
    conditionChange() {
      this.$emit("conditionChange", this.activeCondition);
    }
  },
};
</script>
<style scoped lang='scss'>
.groups {
  position: relative;
  .group {
    border: 1px solid #e8e8e8;
  }
}
.row {
  font-size: 14px;
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  min-height: 40px;
  .group_head {
    position: absolute;
    left: 0px;
    top: 0px;
    color: #999;
    width: 100px;
    height: 100%;
    line-height: 45px;
    padding-left: 10px;
  }
  .group_body {
    padding: 0 100px 0 120px;
    .items {
      padding: 0 0 5px 0;
      // height: 45px;
      .item {
        margin: 5px 36px 0 0;
        height: 18px;
      }
    }
  }
}
.frow:last-child .row {
  border-bottom: 0px;
}
</style>