<template>
  <div class="moregoods">
    <top></top>
    <search></search>
    <!-- 主题内容 -->
    <div class="contain">
      <!-- 面包屑导航 -->
      <crumb ref="crumb" :list="navList" @navClick="navClick"></crumb>
      <!-- 搜索条件区域 -->
      <searchArea
        ref="searchArea"
        :paramsList="paramsList"
        @conditionChange="conditionChange"
      ></searchArea>
      <!-- 关键字排序选择 -->
      <conditionSort ref="conditionSort" @search="loadData()"></conditionSort>
      <!-- 商品列表 -->
      <goodsList
        ref="goodsList"
        style="width: 1225px"
        :dataSource="dataSource"
      ></goodsList>
      <div class="Mtp60">
        <el-pagination
          class="floatR"
          background
          :total="ipagination.totalCount"
          :page-size="ipagination.size"
          :current-page="ipagination.current"
          @size-change="onSizeChange"
          @current-change="onPageChange"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[50, 100, 200, 300]"
        ></el-pagination>
      </div>
      <div class="clearfix Mbm30"></div>
    </div>
    <bottom class="bottom"></bottom>
  </div>
</template>
<script>
import Bottom from "@/components/bottom.vue";
import Search from "@/components/search.vue";
import top from "@/components/top.vue";

import searchArea from "@/components/moreGoods/searchArea";
import crumb from "@/components/moreGoods/crumb";
import conditionSort from "@/components/moreGoods/conditionSort";
import goodsList from "@/components/moreGoods/goodsList";

import { getShopPageDataByParam, getTypePropertySearchParam } from "@/api/user";
import { getGoodsTypeNavigation } from "@/api/product";
import { mapGetters } from "vuex";
import { listMixin } from "@/mixins/listMixins";
export default {
  mixins: [listMixin],
  components: {
    top,
    Search,
    Bottom,
    searchArea,
    crumb,
    conditionSort,
    goodsList,
  },
  name: "MoreGoods",
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      extraParam: {
        typeId: "",
        propertyParam: [],
      },
      url: {
        list: getShopPageDataByParam,
      },
      /* 分页参数 */
      ipagination: {
        totalPage: 0,
        totalCount: 0,
        // 每页数量
        size: 50,
        // 当前页
        current: 1,
      },
      paramsList: [],
      navList: [],
    };
  },
  mounted() {
    this.defaultMehod()
  },
  methods: {
    defaultMehod() {
      this.extraParam.typeId = this.$route.query.typeId;
      this.getTypePropertySearchParam(this.extraParam.typeId);
      this.getGoodsTypeNavigation(this.extraParam.typeId);
      this.loadData();
    },
    getTypePropertySearchParam(typeId) {
      getTypePropertySearchParam(typeId).then((res) => {
        this.$set(this, "paramsList", res.data);
      });
    },
    // 条件变化
    conditionChange(data) {
      this.$set(this.extraParam, "propertyParam", JSON.stringify(data));
      this.loadData();
    },
    // 获取面包屑导航数据
    getGoodsTypeNavigation(typeId) {
      getGoodsTypeNavigation({ typeId }).then((res) => {
        this.$set(this, "navList", res.data);
      });
    },
    // 面包学导航点击事件
    navClick(typeId) {
      this.$router.push({
        name: "MoreGoods",
        query: {
          typeId: typeId,
        },
      });
      this.defaultMehod();
    },
    
    /**
     * 获取数据
     */
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error("请设置url.list属性!")
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg != undefined) {
        this.ipagination.current = arg;
      }
      var params = this.getQueryParams(); //查询条件
      this.loading = true;
      let query = {};
      // 获取关键词搜索
      let type = this.$refs.conditionSort.type;
      let sort = this.$refs.conditionSort.sort;
      if (type == 2) {
        query.salesVolume = sort;
      } else if (type == 3) {
        query.price = sort;
      }
      // 获取价格区间
      let priceSection = this.$refs.conditionSort.getPriceSection();
      this.url.list({...params, ...query, ...priceSection}).then(res => {
        let result = res;
        console.log(res.data)
        if (result.success) {
          this.$set(this, 'dataSource', result.data.records);
          if (arg > 1 && this.dataSource.length === 0) {
            this.loadData(arg - 1)
          }
          this.ipagination.totalCount = result.data.total;
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
        if (res.code === 510) {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
        this.loading = false;
      })
    },
  },
};
</script>
<style scoped>
</style>