<!--
 * @Author: Jerry
 * @Date: 2021-01-12 18:10:33
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-11 17:48:31
 * @Description: file content
-->
<template>
  <div class="crumb">
    <router-link tag="a" class="F28 FBold co_333" :to="{ name: 'index' }">首页</router-link>
    <span v-for="item in list" :key="item.id">
      <i class="F18 co_333 el-icon-arrow-right"></i>
      <el-button plain size="mini" @click="navClick(item.id)">{{ item.categoryName }}</el-button>
    </span>
  </div>
</template>
<script>
// 面包屑导航组件
export default {
  name: "crumb",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    navClick(id) {
      this.$emit('navClick', id)
    }
  }
};
</script>
<style scoped lang="scss">
.crumb {
  padding: 20px 0;
  i {
    margin: 0 5px;
  }
  .el-button {
    position: relative;
    top: -4px;
  }
}
</style>