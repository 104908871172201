<!--
 * @Author: Jerry
 * @Date: 2021-01-13 10:30:44
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-14 23:57:44
 * @Description: file content
-->
<template>
  <div class="conditionSort bg_f4f4">
    <div class="sorts">
      <ul>
        <li
          :class="{ active: type == 1 }"
          class="curPoint"
          @click="selectTypeOne()"
        >
          综合
        </li>
        <li
          :class="{ active: type == 2 }"
          class="curPoint"
          @click="selectTypeTwo()"
        >
          销量 <i class="el-icon-top" v-if="sort == 'asc' && type == 2"></i
          ><i class="el-icon-bottom" v-if="sort == 'desc' && type == 2"></i>
        </li>
        <li
          :class="{ active: type == 3 }"
          class="curPoint"
          @click="selectTypeThree()"
        >
          价格 <i class="el-icon-top" v-if="sort == 'asc' && type == 3"></i
          ><i class="el-icon-bottom" v-if="sort == 'desc' && type == 3"></i>
        </li>
      </ul>
    </div>
    <div class="prices">
      <ul class="">
        <li class="item">
          <el-input
            size="mini"
            placeholder="￥最低价"
            style="width: 80px"
            :min="0"
            v-model="min"
          ></el-input>
        </li>
        <li class="sep">-</li>
        <li class="item">
          <el-input
            size="mini"
            placeholder="￥最高价"
            style="width: 80px"
            v-model="max"
          ></el-input>
        </li>
        <li>
          <el-button type="primary" size="mini" @click="search">确认</el-button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// 条件搜索组件
export default {
  name: "conditionSort",
  data() {
    return {
      // 1：综合，2：销量，3：价格
      type: 1,
      // asc 正序，desc 倒序
      sort: "asc",
      min: null,
      max: null,
    };
  },
  methods: {
    search() {
      this.$emit("search");
    },
    // 综合
    selectTypeOne() {
      this.type = 1;
      this.$emit("search");
    },
    // 销量
    selectTypeTwo() {
      if (this.type == 2) {
        this.sortC();
      } else {
        this.sort = "asc";
      }
      this.type = 2;
      this.$emit("search");
    },
    // 时间
    selectTypeThree() {
      if (this.type == 3) {
        this.sortC();
      } else {
        this.sort = "asc";
      }
      this.type = 3;
      this.$emit("search");
    },
    // 获取价格区间
    getPriceSection() {
      let priceSectionStart = this.min ? this.min : 0;
      let priceSectionEnd = this.max ? this.max : "";

      if (priceSectionEnd == "") {
        return {
          priceSectionStart: Number(priceSectionStart),
        };
      } else {
        return {
          priceSectionStart: Number(priceSectionStart),
          priceSectionEnd: Number(priceSectionEnd),
        };
      }
    },
    sortC() {
      if (this.sort == "asc") {
        this.sort = "desc";
      } else {
        this.sort = "asc";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.conditionSort {
  margin: 10px 0 0;
  height: 50px;
  width: 100%;
  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .sorts {
    float: left;
    height: 100%;
    li {
      height: 50px;
      line-height: 48px;
      float: left;
      padding: 0 20px;
      font-size: 14px;
      cursor: pointer;
      &.active {
        padding: 0 20px;
        background-color: #ff5b0c;
        color: #ffffff;
      }
    }
  }
  .prices {
    float: left;
    height: 100%;
    margin-left: 20px;
    ul {
      padding: 11px 0;
    }
    li {
      float: left;
      height: 100%;
      margin-right: 5px;
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>